
import { isMobile } from '@/utils/utils'
import eventBus from '@/utils/event-bus'
import { getLoginQrImg, getCustomPageSetting } from '@/api/login'
import { listNewsList, getSecondNodes } from '@/api/policy'
import { openListAnswer } from '@/api/answer'
const cmsNewsUrl = process.env.CMS_NEWS_URL
export default {
  name : 'CompanyHome',
  components : {
  },
  filters : {
    dataFormat( text ) {
      if ( text !== null ) {
        return Number( text / 1024000 ).toFixed( 1 ) + 'MB'
      }
    }
  },
  layout : 'base',
  scrollToTop : true,
  async asyncData( context ) {
    let newsData = null
    let questionList = null
    let secondNodes = null
    try {
      const resA = await listNewsList()
      newsData = resA.ret
      // console.log('newsData>>',resA)
      const resB = await openListAnswer( {
        pageNum : 1,
        pageSize : 3
      } )
      questionList = resB.rows
      const resC = await getSecondNodes()
      secondNodes = resC.rows
    } catch {
      // ignore error
    }
    return { newsData, questionList, secondNodes }
  },
  data() {
    return {
      cmsNewsUrl,
      classOption : {
        step : 0.5
      },
      newsIcon : require( '@/assets/images/indexHome/1.png' ),
      qaIcon : require( '@/assets/images/indexHome/2.png' ),
      nodesIcon : require( '@/assets/images/indexHome/3.png' ),
      tIcon : require( '@/assets/images/indexHome/policy3.png' ),
      qa1Icon : require( '@/assets/images/indexHome/icon_admin.png' ),
      qa2Icon : require( '@/assets/images/indexHome/qa.png' ),
      carousels : [
        'https://jxm.asuncloud.net/prod-api/file/image/5379/img_bg (2).png', 'https://jxm.asuncloud.net/prod-api/file/image/5379/img_bg (2).png'
      ]
    }
  },

  computed : {},
  watch : {
  },
  created() {
    this.listNewsList()
    this.getQuestions()
    this.getSecondNodes()
    getCustomPageSetting().then( r => {
      const data = JSON.parse( r.msg )
      console.log( 2342, data )
      this.carousels = data.carousel
    } )
  },
  mounted() {
    const _this = this
    const ua = isMobile()
    if ( process.browser ) {
      if ( ua && !this.$route.query.pc ) {
        window.location.href = `/redirect/mobile`
      }
      if ( !this.$store.getters.token && this.$store.getters.token == undefined ) {
        const qrImg = localStorage.getItem( 'loginQrImg' )
        const qrScene = localStorage.getItem( 'loginQrScene' )

        if ( qrImg == null || qrImg == undefined || qrImg == '' || qrScene == null || qrScene == undefined || qrScene ==
          '' ) {
          const r = getLoginQrImg( process.env.MINI_PG_VERSION )
          r.then( ( res ) => {
            if ( res.code == 200 ) {
              localStorage.setItem( 'loginQrImg', res.data )
              localStorage.setItem( 'loginQrScene', res.scene )
            }
          } )
        }
      }
    }
    this.qrCreateList = JSON.parse( JSON.stringify( this.$store.getters.qrList ) )
    eventBus.$on( 'loginOver', k => {
      if ( _this.$store.getters.token ) {
        _this.closedLogin()
      }
    } )
  },

  methods : {
    openLogin() {
      eventBus.$emit( 'loginOrRegister', 'openLogin' )
    },
    closedLogin() {
      if ( this.$store.getters.token && this.$store.getters.token != undefined ) {
        if ( this.qrCreateList.length > 0 ) {
          console.log( 45324, this.qrCreateList )
          setTimeout( () => {
            this.saveQrToUser()
          }, 800 )
        }
      }
    },
    async listNewsList() {
      const res = await listNewsList()
      res.ret.map( i => {
        i.addTime = i.addTime.split( ' ' )[0]
      } )
      this.newsData = res.ret
    },
    async getQuestions() {
      const res = await openListAnswer( {
        pageNum : 1,
        pageSize : 3
      } )
      res.rows.map( i => {
        if ( parseInt( i.createBy ) ) {
          i.createBy = i.createBy.replace( /^(\d{3})\d{4}(\d{4})$/, '$1****$2' )
        }
      } )
      this.questionList = res.rows
    },
    async getSecondNodes() {
      const res = await getSecondNodes()
      res.rows.map( i => {
        i.createTime = i.createTime.split( ' ' )[0]
      } )
      this.secondNodes = res.rows
    },
    goToMore( v ) {
      if ( v === 1 ) {
        window.open( process.env.CMS_NEWS_URL )
      } else if ( v === 2 ) {
        eventBus.$emit( 'activeIndex', '6' )
        this.$router.push( { path : '/community' } )
      }
    },
    goToComm() {
      eventBus.$emit( 'activeIndex', '6' )
    },
    toPlainText( html ) {
      return html?.replace( /<[^>]*>/g, '' ).replace( /[\r\n\s]/g, '' ).trim()
    }
  }
}

